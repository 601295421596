import { Button, Col, DatePicker, Input, Row, Space, Spin, Table, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaEraser, FaFolderOpen, FaQuestionCircle, FaRegHandPaper,FaFilter } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { referencesApi } from '../../../../api/referencesApi';
import TableI3links from '../../../../commons/commonComponents/tableI3links/TableI3links';
import { FormatDate } from '../../../../commons/commonFunctions/FormatDate';
import useWindowWidth from '../../../../hooks/useWindowWidth/useWindowWidth';
import '../../css/style.css';
;
const cryptoJS = require('crypto-js');
const { RangePicker } = DatePicker;


Details.propTypes = {};

function Details(props) {
  const windowWidth = useWindowWidth();
  const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
  const { filter, spinningProp } = props;
  const [header, setHeader] = useState({});
  const [listTask, setListTask] = useState([]);
  const [listTaskFilter, setlistTaskFilter] = useState([]);
  const [listUser, setListUser] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [notes, setNotes] = useState(() => {
    return filter?.notes ? filter?.notes : '';
  });
  const [pagination, setPagination] = useState({
    totals: 1,
    limit: 10,
    page: 1,
  });
  const encryptKey = process.env.REACT_APP_ENCRYPT_KEY;
  const location = useLocation();
///////////////////////////////////////////////////////
const [sortFilter, setSortFilter] = useState({
  filterProcessName: '',
  filterTaskName: '',
  filterAdminName: '',
  filterStatusName: '',
  filterStartTime: [null, null],
  filterEndTime: [null, null],
  filterStartTimeReality: [null, null],
  filterEndTimeReality: [null, null],
});
const handleSearchText = (selectedKeys, confirm, dataIndex, filterName) => {
  confirm();
  setSortFilter((prevValue) => {
    const newListSort = { ...prevValue };
    newListSort[filterName] = selectedKeys[0];
    return { ...newListSort };
  });
};
const handleSearchDate = (selectedKeys, confirm, dataIndex, filterName) => {
  confirm();
  setSortFilter((prevValue) => {
    const newListSort = { ...prevValue };
    newListSort[filterName] = selectedKeys;
    return { ...newListSort };
  });
};
const handleReset = (clearFilters,filterName,confirm) => {
  if(filterName === 'filterStartTime' || filterName === 'filterEndTime' || filterName === 'filterStartTimeReality'|| filterName === 'filterEndTimeReality'){
    setSortFilter((prevValue) => {
      const newListSort = { ...prevValue };
      newListSort[filterName] = [];
      return { ...newListSort };
    });
  }else{
    setSortFilter((prevValue) => {
      const newListSort = { ...prevValue };
      newListSort[filterName] = '';
      return { ...newListSort };
    });
  }
  confirm()
  clearFilters();
};
///// lọc theo text
const getColumnSearchProps = (dataIndex, placeholder, filterName) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        placeholder={placeholder}
        value={`${selectedKeys[0] || ''}`}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearchText(selectedKeys, confirm, dataIndex, filterName)}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          type='primary'
          onClick={() => handleSearchText(selectedKeys, confirm, dataIndex, filterName)}
          icon={<FaFilter />}
          size='small'
          style={{
            width: 90,
          }}
        ></Button>
        <Button
          onClick={() => {
            setSelectedKeys([])
            return clearFilters && handleReset(clearFilters,filterName,confirm)
          }}
          size='small'
          type='primary'
          style={{
            width: 90,
          }}
          icon={<FaEraser />}
        ></Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <FaFilter
      style={{
        color: filtered ? '#000' : undefined,
      }}
    />
  ),
});
///// lọc theo ngày
const getColumnSearchDateProps = (dataIndex, placeholder, filterName) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: 15, padding: 10 }}>
        <div>
          <RangePicker
            type=''
            defaultValue={sortFilter.filterNextStartTime}
            value={selectedKeys.length ? selectedKeys.map((date) => (date ? moment(date) : null)) : []}
            onChange={(dates) => {
              setSelectedKeys(dates ? [moment(dates[0]._d).hours(0).minutes(0).seconds(0).milliseconds(0).toDate(), moment(dates[1]._d).hours(0).minutes(0).seconds(0).milliseconds(0).toDate()] : []);
            }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            onClick={() => {
              handleSearchDate(selectedKeys, confirm, dataIndex, filterName);
            }}
            size='small'
            type='primary'
            style={{ width: 90, marginRight: 8 }}
            icon={<FaFilter />}
          />
          <Button
            onClick={() => {
              setSelectedKeys([])
            return clearFilters && handleReset(clearFilters,filterName,confirm)
            }}
            size='small'
            type='primary'
            style={{ width: 90 }}
            icon={<FaEraser />}
          />
        </div>
      </div>
    </div>
  ),
  filterIcon: (filtered) => (
    <FaFilter
      style={{
        color: filtered ? '#000' : undefined,
      }}
    />
  ),
});

const Widthtable = windowWidth - 1075 - 160;
const [columns, setColumns] = useState(() => {
  return [
    { title: '処理順', align: 'center', dataIndex: 'execution_no', key: '1', ellipsis: true, width: 70 },
    {
      title: 'タスク参照',
      align: 'center',
      dataIndex: '',
      key: '2',
      ellipsis: true,
      width: 100,
      render: (item) => {
        if (item.user_task_start_achievement && new Date(item.user_task_start_achievement) <= new Date()) {
          return item.user_id == userInfo.user_id && item.user_status_name === '処理中' ? (
            <FaFolderOpen
              size={20}
              color='gray'
              onClick={() => {
                window.open(`${window.location.origin}/taskConfirm?trn_manage_id=${item.trn_manage_id}&task_id=${item.task_id}`);
              }}
            />
          ) : (
            <FaFolderOpen
              size={20}
              color='gray'
              onClick={() => {
                window.open(
                  `${window.location.origin}/process/task?searchId=${cryptoJS.AES.encrypt(
                    JSON.stringify({
                      type: item.type,
                      trn_manage_id: item.trn_manage_id,
                      task_id: item.task_id,
                      user_id: item.user_id,
                    }),
                    encryptKey
                  ).toString()}`
                );
              }}
            />
          );
        } else {
          return <></>;
        }
      },
    },

    { title: 'タスク名', align: 'left', dataIndex: 'task_name', key: '3', ellipsis: true, 
      width: (Widthtable * 0.55) >  100 ? (Widthtable * 0.55) : 100 ,
      sorter: {
        compare: (a, b) => a.task_name?.localeCompare(b.task_name),
        multiple: 1,
      },
      ...getColumnSearchProps('task_name', 'タスク名', 'filterTaskName'),
     },
    { title: '担当者', align: 'left', dataIndex: 'user_name', key: '4', ellipsis: true, 
      width: (Widthtable * 0.45) > 100 ? Widthtable * 0.45 : 100,
      sorter: {
        compare: (a, b) => a.user_name?.localeCompare(b.user_name),
        multiple: 1,
      },
      ...getColumnSearchProps('user_name', '担当者', 'filterAdminName'),
     },
    {
      title: '引き受け',
      align: 'center',
      width: 80,
      dataIndex: '',
      key: '2',
      ellipsis: true,
      render: (item) => {
        if (+item.worker_decision_type === 100) {
          if ((item.user_status_name === '処理中' || (+item.user_status === 2 && !item.user_task_start_limit && !item.user_task_end_limit && +item.execution_no != 1) || (+item.user_status === 2 && !item.user_task_end_limit && +item.execution_no === 1)) && +item.notification_type !== 1 ) {
            return (
              <FaRegHandPaper
                size={18}
                color='#03FFB4'
                onClick={() => {
                  window.open(`${window.location.origin}/taskAssign?trn_manage_id=${item.trn_manage_id}&task_id=${item.task_id}`);
                }}
              />
            );
          } else {
            return (
              <FaRegHandPaper
                size={18}
                color={item.user_task_start_achievement ? '03FFB4' : '#FF0000'}
                onClick={() => {
                  window.open(`${window.location.origin}/taskAssign?trn_manage_id=${item.trn_manage_id}&task_id=${item.task_id}`);
                }}
              />
            );
          }
        } else {
          return <></>;
        }
      },
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>ステータス </span>
          <Tooltip
            color={'#00a199'}
            placement='topLeft'
            title={
              <div>
                <p style={{ marginBottom: 0 }}>① 未設定：引き受け、開始時設定が行われていない状態</p>
                <p style={{ marginBottom: 0 }}>② 待機中：タスク開始待ちの状態</p>
                <p style={{ marginBottom: 0 }}>③ 処理中：タスク開始メールが送信済の状態</p>
                <p style={{ marginBottom: 0 }}>④ 完了 ：完了報告が行われた状態</p>
              </div>
            }
            arrowPointAtCenter
          >
            <FaQuestionCircle cursor='pointer' style={{ marginLeft: 5 }} />
          </Tooltip>
        </div>
      ),
      align: 'left',
      dataIndex: '',
      key: '6',
      ellipsis: true,
      width: 142,
      sorter: {
        compare: (a, b) => a.user_status_name?.localeCompare(b.user_status_name),
        multiple: 1,
      },
      ...getColumnSearchProps('user_status_name', 'タスク名', 'filterStatusName'),
      render: (item) => {
        return (
          <Tooltip
            color={'#00a199'}
            placement='topLeft'
            title={
              item.user_status_name === '未設定' ? (
                <div>
                  {+item.user_status === 2 && +item.notification_type === 1 && <span style={{ display: 'block' }}>引き受けが行われていません。</span>}
                  {+item.user_status === 2 && !item.user_task_start_limit && !item.user_task_end_limit && +item.execution_no != 1 && <span style={{ display: 'block' }}>開始時設定が行われていません。</span>}
                  {+item.user_status === 2 && !item.user_task_end_limit && +item.execution_no === 1 && <span style={{ display: 'block' }}>開始時設定が行われていません。</span>}
                </div>
              ) : null
            }
            arrowPointAtCenter
          >
            <span style={{ color: item.user_status_name === '未設定' ? 'red' : '#000' }}>{item.user_status_name}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'タスク開始予定日時',
      align: 'left',
      dataIndex: '',
      key: '7',
      ellipsis: true,
      width: 165,
      sorter: {
        compare: (a, b) => new Date(a.user_task_start_limit) - new Date(b.user_task_start_limit),
        multiple: 1,
      },
      ...getColumnSearchDateProps('user_task_start_limit', 'タスク開始予定日時', 'filterStartTime'),
      render: (item) => {
        return !item.user_task_start_achievement && new Date(item.user_task_start_limit) < new Date() && item.user_status !== '10' ? <div style={{ color: 'red' }}>{item.user_task_start_limit_display}</div> : <div>{item.user_task_start_limit_display}</div>;
      },
    },
    { title: 'タスク開始実績日時', align: 'left', dataIndex: 'user_task_start_achievement_display', key: '8', ellipsis: true, 
      width: 165,
      sorter: {
        compare: (a, b) => new Date(a.user_task_start_achievement_display) - new Date(b.user_task_start_achievement_display),
        multiple: 1,
      },
      ...getColumnSearchDateProps('user_task_start_achievement_display', 'タスク開始実績日時', 'filterStartTimeReality'),
     },
    {
      title: 'タスク終了予定日時',
      align: 'left',
      dataIndex: '',
      key: '9',
      ellipsis: true,
      width: 165,
      sorter: {
        compare: (a, b) => new Date(a.user_task_end_limit_display) - new Date(b.user_task_end_limit_display),
        multiple: 1,
      },
      ...getColumnSearchDateProps('user_task_end_limit_display', 'タスク終了予定日時', 'filterEndTime'),
      render: (item) => {
        return !item.user_task_end_achievement && new Date(item.user_task_end_limit) < new Date() && item.user_status !== '10' ? <div style={{ color: 'red' }}>{item.user_task_end_limit_display}</div> : <div>{item.user_task_end_limit_display}</div>;
      },
    },
    { title: 'タスク終了実績日時', align: 'left', dataIndex: 'user_task_end_achievement_display', key: '10', ellipsis: true, 
      width: 165,
      sorter: {
        compare: (a, b) => new Date(a.user_task_end_achievement_display) - new Date(b.user_task_end_achievement_display),
        multiple: 1,
      },
      ...getColumnSearchDateProps('user_task_end_achievement_display', 'タスク終了実績日時', 'filterEndTimeReality'),
     },
    { title: '', align: 'left', dataIndex: '', key: '11', ellipsis: true },
  ];
});


 ///sort khi có thông tin sort thay đổi
 useEffect(() => {
  let dataFilterTasks = [...listTask];
  if (sortFilter.filterTaskName?.trim()) {
    dataFilterTasks = dataFilterTasks.filter((item) => item.task_name.includes(sortFilter.filterTaskName?.trim()));
  }
  if (sortFilter.filterAdminName?.trim()) {
    dataFilterTasks = dataFilterTasks.filter((item) => item.user_name.includes(sortFilter.filterAdminName?.trim()));
  }
  if (sortFilter.filterStatusName?.trim()) {
    dataFilterTasks = dataFilterTasks.filter((item) => item.user_status_name.includes(sortFilter.filterStatusName?.trim()));
  }
  if (sortFilter.filterStartTime[0] && sortFilter.filterStartTime[1]) {
    dataFilterTasks = dataFilterTasks.filter((item) => item.user_task_start_limit && new Date(`${item.user_task_start_limit}`) >= new Date(sortFilter.filterStartTime[0]) && new Date(`${item.user_task_start_limit}`) <= new Date(sortFilter.filterStartTime[1]));
  }
  if (sortFilter.filterEndTime[0] && sortFilter.filterEndTime[1]) {
    dataFilterTasks = dataFilterTasks.filter((item) => item.user_task_end_limit_display && new Date(`${item.user_task_end_limit_display}`) >= new Date(sortFilter.filterEndTime[0]) && new Date(`${item.user_task_end_limit_display}`) <= new Date(sortFilter.filterEndTime[1]));
  }
  if (sortFilter.filterStartTimeReality[0] && sortFilter.filterStartTimeReality[1]) {
    dataFilterTasks = dataFilterTasks.filter((item) => item.user_task_start_achievement_display && new Date(`${item.user_task_start_achievement_display}`) >= new Date(sortFilter.filterStartTimeReality[0]) && new Date(`${item.user_task_start_achievement_display}`) <= new Date(sortFilter.filterStartTimeReality[1]));
  }
  if (sortFilter.filterEndTimeReality[0] && sortFilter.filterEndTimeReality[1]) {
    dataFilterTasks = dataFilterTasks.filter((item) => item.user_task_end_achievement_display && new Date(`${item.user_task_end_achievement_display}`) >= new Date(sortFilter.filterEndTimeReality[0]) && new Date(`${item.user_task_end_achievement_display}`) <= new Date(sortFilter.filterEndTimeReality[1]));
  }
  
  setlistTaskFilter(() => {
    return dataFilterTasks.map((item, index) => {
      return { ...item, no: index + 1 };
    });
  });
  setPagination((prevPagination) => {
    return { ...prevPagination };
  });
}, [sortFilter]);

///////////////////////////////////////////////////////



  
  const handlePaginationNexted = (value) => {
    if (value) {
      const newPagination = { ...pagination, ...value };
      setPagination(newPagination);
    }
  };
  const handleSubmitNote = async () => {
    try {
      setSpinning(true);
      const newValue = notes.trim();
      const data = { notes: newValue, team_id: filter?.team_id ? filter.team_id : -1, trn_manage_id: filter?.trn_manage_id ? filter.trn_manage_id : -1 };

      if (newValue) {
        const res = await referencesApi.addNote(data);
        if (res.results) {
          toast.success(`${res.messages ? res.messages : ''}`, {
            toastId: 'success',
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
          await refresh();
        } else {
          toast.warning(`${res.messages ? res.messages : ''}`, {
            toastId: 'fail',
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSpinning(false);
    }
  };
  useEffect(() => {
    (async () => {
      if (!filter) {
        return;
      }
      await refresh();
    })();
  }, [filter]);

  const refresh = async () => {
    try {
      const teamQuery = { team_id: filter.team_id, trn_manage_id: filter.trn_manage_id, type: filter.type };
      if (!teamQuery.team_id) {
        return;
      }
      setSpinning(true);
      const dataProcess = await referencesApi.getProcessDtl(teamQuery);
      const dataTasks = await referencesApi.getTask(teamQuery);
      const dataUsers = await referencesApi.getUsers(teamQuery);

      const dataProcessDtl = [...dataProcess.data].map((item, index) => {
        return {
          ...item,
          type: filter.type,
          process_start_achievement: item.process_start_achievement ? FormatDate(new Date(item.process_start_achievement)) : '',
          process_end_achievement: item.process_end_achievement ? FormatDate(new Date(item.process_end_achievement)) : '',
        };
      });
      const dataListTask = [...dataTasks.data]
        .sort((a, b) => a.execution_no - b.execution_no)
        .map((item, index) => {
          return {
            ...item,
            type: filter.type,
            user_task_start_limit_display: item.user_task_start_limit ? FormatDate(new Date(item.user_task_start_limit)) : '',
            user_task_start_achievement_display: item.user_task_start_achievement ? FormatDate(new Date(item.user_task_start_achievement)) : '',
            user_task_end_limit_display: item.user_task_end_limit ? FormatDate(new Date(item.user_task_end_limit)) : '',
            user_task_end_achievement_display: item.user_task_end_achievement ? FormatDate(new Date(item.user_task_end_achievement)) : '',
          };
        });
      const dataListUser = [...dataUsers.data].map((item, index) => {
        return {
          ...item,
          no: index + 1,
        };
      });
      setNotes(dataProcessDtl[0].notes);
      setHeader(dataProcessDtl[0]);
      setListTask(dataListTask);
      setlistTaskFilter(dataListTask)
      setListUser(dataListUser);
      setSpinning(false);
    } catch (error) {
      setSpinning(false);
      console.log(error);
    }
  };

  return (
    <div
    // style={{ animation: 'animation_righttoleft 0.5s' }}
    >
      <Spin spinning={spinning} tip='ローディング．．．'>
        <Row gutter={[10, 10]}>
          <Col span={6} style={{ fontSize: 24 }}>
            <div style={{ background: '#fff', marginTop: 10 }}>
              <Table size='small' type='radio' pagination={false} dataSource={[{ process_name: `プロセスのステータス：${header?.process_status_name ? header?.process_status_name : ''}` }, { process_name: `プロセス実行者： ${header?.process_practitioner ? header.process_practitioner : ''} ` }, { process_name: `プロセス開始実績： ${header?.process_start_achievement ? header.process_start_achievement : ''}` }, { process_name: `プロセス終了実績： ${header?.process_end_achievement ? header.process_end_achievement : ''}` }, { process_name: `処理管理ID： ${header?.trn_manage_id ? header.trn_manage_id : ''}` }]} columns={[{ title: `プロセス名：${header?.process_name ? header.process_name : ''}`, align: 'left', dataIndex: 'process_name', key: '2', ellipsis: true }]} />
            </div>
          </Col>
          <Col span={12}>
            {location.pathname.includes('run') && (
              <div className='text_note_detail' style={{ marginTop: 10, maxWidth: 300, position: 'relative' }}>
                {/* No12 */}
                <h2 className='title_text_notes' style={{ lineHeight: '21px', fontWeight: 600, color: '#fff', background: '#00a199', margin: 0, height: 38.8, borderTopLeftRadius: 1, borderTopRightRadius: 1, padding: 8 }}>
                  メモ
                </h2>
                <input
                  maxLength={21}
                  onChange={(e) => {
                    const text = e.target.value;
                    if (text.length > 20) {
                      toast.warning('20字以内で入力してください。', {
                        toastId: 'length_content_task',
                        position: 'top-right',
                        autoClose: 3000,
                        closeOnClick: true,
                        theme: 'light',
                        pauseOnHover: false,
                        hideProgressBar: true,
                      });
                      return;
                    }
                    setNotes(text);
                  }}
                  value={notes}
                  type='Text'
                  placeholder='任意の情報を保存できます'
                  className='text_notes'
                />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: 20, position: 'absolute', width: '100%', zIndex: 99 }}>
                  <Button
                    disabled={false}
                    className={'bg__green i3link__btn'}
                    onClick={() => {
                      handleSubmitNote();
                    }}
                  >
                    保存
                  </Button>
                </div>
              </div>
            )}
          </Col>
          <Col span={6}>
            <div className='common__content flex__box flex__jus__sb flex__gap__cl--20'>
              <Table
                size='small'
                type='radio'
                pagination={
                  listUser.length <= 5
                    ? false
                    : {
                        pageSize: 5,
                        position: ['bottomCenter', 'bottomCenter'],
                      }
                }
                dataSource={listUser}
                columns={[
                  { title: '', align: 'center', dataIndex: 'no', key: '1', ellipsis: false, width: 50 },
                  { title: 'プロセス管理者', align: 'left', dataIndex: 'user_name', key: '2', ellipsis: true },
                ]}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className='' style={{ overflowX: 'auto', padding: '20px 0px' }}>
              <TableI3links 
              spinning={spinningProp || spinning} 
              className='common__table title_table_small' 
              refresh={refresh} type='radio' 
              dataSource={listTaskFilter} 
              total={listTaskFilter.length} 
              columns={columns} 
              rowClassName={(record) => (record.user_status === '5' ? 'table-row-processing' : 'table-row-normal')} 
              scroll={{ x: '100%' }} 
              Pagination={pagination} 
              handlePaginationNexted={handlePaginationNexted} />
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Details;
