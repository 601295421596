import { Button, Col, Modal, Radio, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalConfirm from '../../../../../commons/commonComponents/modalConfirm/ModalConfirm';
import { processState } from '../../../../../redux/selector';
import processSlice from '../../../reduxProcess/processSlice';
import FilterUser from './FilterUser';
import queryString from 'query-string';
StepCreateManagerTask.propTypes = {};

function StepCreateManagerTask(props) {
  const { nextStep, prevStep } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const processInfo = useSelector(processState);
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [assignMode, setAssignMode] = useState(0);
  const [disableAssignMode, setDisableAssignMode] = useState(false);
  const [taskInfo, setTaskInfo] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [listTask, setListTask] = useState(() => {
    return processInfo.tasks || [];
  });
  const [showModal, setShowModal] = useState(()=>{
    let check = false
    const searchParam = queryString.parse(location.search);
   if(searchParam.template_id){
    for (let index = 0; index < processInfo.tasks.length; index++) {
      const task = processInfo.tasks[index];
      if(task.listUser.length === 0){
        check = true
        break
      }
    }
   }
    return check
  });
  
  ////////// chuyển màn hình
  const renderStep = (step) => {
    switch (step) {
      case 0:
        return (
          <div className='process__new--intro common__process__listtaskbox'>
            <h2 className='color__title__black mt--10'>各タスクに担当者の設定を行います。</h2>
            <h2 className='mb--30 color__title__black '>設定するタスクを選択してください。</h2>
            <div className='process__new--intro '>
              <div className='common__process__listtask  mb--30'>
                <div className='common__flex process__listtask'>
                  {listTask.length > 0 &&
                    listTask.map((item, index) => {
                      return (
                        <div key={+item.id} className='common__flex process__task--item'>
                          <div className='common__flex process__task--itemL'>
                            <div className='process__task--item--number'>
                              <p>タスク</p>
                              <p>{index + 1}</p>
                            </div>
                            <div className='common__flex process__task--item--name'>
                              <div className='common__flex process__task--item--input'>{item.task_name}</div>
                            </div>
                          </div>
                          <div className='process__task--itemR'>
                            <Button
                              className={item?.listUser.length > 0 ? 'bg__mid__blue process__task--itemR--btn' : 'process__task--itemR--btn'}
                              onClick={() => {
                                setStep(step + 1);
                                setTaskInfo({ ...item });
                                setAssignMode(+item.worker_decision_type === 100 ? 1 : 0);
                              }}
                            >
                              {item?.listUser.length > 0 ? '変更' : '設 定'}
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className='process__new--intro common__process__listtaskbox'>
            <div className='mb--10 mt--10'>
              <h2 className='color__title__black'>
                タスク {taskInfo.index + 1}「{taskInfo.task_name}」
              </h2>
              <h2 className='color__title__black'>担当者の設定を確認して、内容を確定してください。</h2>
              <div className='color__title__black'>
                <Radio.Group onChange={onChangeAssignMode} value={assignMode}>
                  <Space direction='vertical'>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
                      <Radio value={0}>
                        <Row style={{ width: 700 }}>
                          <Col span={4}>
                            <p>デフォルト：</p>
                          </Col>
                          <Col span={20}>
                            <p style={{ textAlign: 'left' }}>指示内容が設定された全員に送信されます。</p>
                          </Col>
                        </Row>
                      </Radio>
                      <Radio value={1} disabled={disableAssignMode}>
                        <Row style={{ width: 700 }}>
                          <Col span={4}>
                            <p style={{marginBottom:0 }}> 引き受け型：</p>
                          </Col>
                          <Col span={20}>
                            <p style={{ textAlign: 'left',marginBottom:0 }}>タスク開始前に「 担当者引き受けリクエスト」メールが設定された全員に送信されます。</p>
                          </Col>
                        </Row>
                        <Row style={{ width: 700 }}>
                          <Col span={4}>
                          </Col>
                          <Col span={20}>
                            <p style={{ textAlign: 'left' }}>メールから1人が担当者を引き受けることで、指示内容が引き受けた担当者に送信されます。</p>
                          </Col>
                        </Row>
                      </Radio>
                    </div>
                  </Space>
                </Radio.Group>
              </div>
            </div>
            <div className='common__process__listtask  mb--30'>
              <FilterUser newProcess={processInfo.newProcess} taskInfo={taskInfo} handleAdd={handleAddUser} handleRemove={handleRemoveUser} />
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const onChangeAssignMode = (e) => {
    setAssignMode(e.target.value);
    let newTaskInfo = { ...taskInfo };
    if (e?.target.value === 0) {
      newTaskInfo.worker_decision_type = 0
    } else if (e?.target.value === 1) {
      newTaskInfo.worker_decision_type = 100;
    }
    setTaskInfo(newTaskInfo);
  };

  /////add user
  const handleAddUser = (list) => {
    let newTaskInfo = { ...taskInfo };
    newTaskInfo.listUser = [...list.listUser];
    // Issue #23
    //newTaskInfo.worker_decision_type = list?.listUser[0]?.type === '起案者' ? 1 : 0;
    if(list?.listUser[0]?.type === '起案者'){
      setAssignMode(0);
      setDisableAssignMode(true);
      newTaskInfo.worker_decision_type = 1;
    }
    else{
      setDisableAssignMode(false);
      if (assignMode === 0) {
        newTaskInfo.worker_decision_type = 0;
      } else if (assignMode === 1) {
        newTaskInfo.worker_decision_type = 100;
      }
    }
    // Issue #23
    setTaskInfo(newTaskInfo);
  };
  /////remove user
  const handleRemoveUser = (id) => {
    const newTaskInfo = { ...taskInfo };
    const newListUser = taskInfo.listUser.filter((item) => item.user_id !== id);
    const type = newListUser.length === 0 ? null : taskInfo.type;
    newTaskInfo.listUser = [...newListUser];
    newTaskInfo.type = type;
    setTaskInfo(newTaskInfo);
  };

  ////////// sử lí trước khi quay về bước trước đó theo
  const handlePrevStep = () => {
    if (step > 0) {
      setStep(step - 1);
      return;
    }
    if (prevStep && step === 0) {
      prevStep();
      return;
    }
  };

  ////////// sử lí trước khi chuyển sang bước tiếp theo
  const handleNextStep = () => {
    const check = checkDisableBtn();
    if (step === 0 && check) {
      setStep(step + 1);
      return;
    }
    if (step === 0 && !check) {
      nextStep();
      return;
    }
    if (step === 1) {
      setStep(step - 1);
      const newTaskInfo = { ...taskInfo };
      newTaskInfo.listUser = newTaskInfo.listUser.filter((item) => item.full_name);
      const action = processSlice.actions.editTask(newTaskInfo);
      dispatch(action);
      setTaskInfo({});

      return;
    }
  };

  /////////check điều kiện của nút next
  const checkDisableBtn = () => {
    if (step === 0) {
      const check = listTask.filter((item) => {
        return item.listUser ? (item.listUser.length === 0 ? true : false) : true;
      });
      return check.length > 0 ? true : false;
    }
    if (step === 1) {
      const check = taskInfo.listUser ? (taskInfo.listUser.length === 0 || (taskInfo.listUser.length > 0 && taskInfo.listUser[0] === null) || (taskInfo.listUser.length > 0 && taskInfo.listUser.filter((item) => item.full_name !== '').length === 0) ? true : false) : true;
      return check;
    }
  };

  ////////////////
  useEffect(() => {
    setDisableAssignMode(taskInfo?.listUser && taskInfo?.listUser.length > 0 && taskInfo?.listUser[0]?.type === '起案者');
  }, [taskInfo]);

  ////////////////
  useEffect(() => {
    setListTask(() => {
      return processInfo.tasks || [];
    });
  }, [processInfo]);
  ////////////huy create process
  const handleCancelCreateProcess = () => {
    const searchParam = queryString.parse(location.search);
    const action = processSlice.actions.newProcess({});
    dispatch(action);
    if(searchParam.process_id){
      navigate({
        pathname: `/process`,
      });
    }else{
      navigate({
        pathname: `/template`,
      });
    }
    return;
    // const action = processSlice.actions.newProcess({});
    // dispatch(action);
    // setOpenModal(false);
    // navigate('/process');
    // return;
  };
  const handleCancelConfirm = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if(step === 1){
      setAssignMode(+taskInfo.worker_decision_type === 100 ? 1 : 0)
    }
  }, [step]);
  return (
    <>
      <div className='process__content__bottom--box'>
        <div className='process__new--content process__new--content--custom'>{renderStep(step)}</div>
        <div className='common__process__control'>
          <div className='common__flex common__process__control--listbtn'>
            <div className='common__flex common__process__control--btnL'>
              <Button
                className={step === 0 ? `bg__red common__process__control--btn` : `bg__blue common__process__control--btn`}
                onClick={() => {
                  if (step === 1) {
                    setStep(0);
                  }
                  if (step === 0) {
                    setOpenModal(true);
                  }
                }}
              >
                {step === 0 ? 'キャンセル' : ' 前に戻る'}
              </Button>
              <ModalConfirm open={openModal} handleCancelConfirm={handleCancelConfirm} handleSubmit={handleCancelCreateProcess} textConfirm={`現在設定中の内容は破棄されます。`} textConfirmMid={`設定を終了してよろしいですか？`} />
            </div>

            <div className='common__flex common__process__control--btnR'>
              {step === 0 && (
                <>
                  <Button disabled={false ? true : false} className={false ? 'common__process__bottom--btn--disable' : 'bg__blue common__process__control--btn'} onClick={handlePrevStep}>
                    前に戻る
                  </Button>
                  <Button disabled={checkDisableBtn()} className={checkDisableBtn() ? 'common__process__bottom--btn--disable common__process__control--btn' : 'bg__green common__process__control--btn'} onClick={handleNextStep}>
                    次へ進む
                  </Button>
                </>
              )}
              {step === 1 && (
                <>
                  <Button disabled={checkDisableBtn()} className={checkDisableBtn() ? 'common__process__bottom--btn--disable common__process__control--btn' : 'bg__green common__process__control--btn'} onClick={handleNextStep}>
                    内容を確定
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
      className=''
      maskClosable={false}
      closable={true}
      title=''
      open={showModal}
      onCancel={() => {
        setShowModal(false)
      }}
      forceRender
      footer={[
        <div key={0} className='modle__user__btn flex__box flex__jus__c'>   
          <Button
            className='i3link__btn bg__blue'
            onClick={() => {
             setShowModal(false)
            }}
          >
            OK
          </Button>
        </div>,
      ]}
    >
      <p style={{marginBottom:0}}>担当者を設定してください。</p>
     
    </Modal>
    </>
  );
}

export default StepCreateManagerTask;
