import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  process_name: '',
  trigger_interval: null,
  trigger_start_time: null,
  trigger_type: null,
  newProcess:false,
  schedules:{
    listDayOfWeek:[],
    listWeekOfMonth:[
      {
        value: '1',
        label: '第1',
        days: [],
      },
      {
        value: '2',
        label: '第2',
        days: [],
      },
      {
        value: '3',
        label: '第3',
        days: [],
      },
      {
        value: '4',
        label: '第4',
        days: [],
      },
      {
        value: '5',
        label: '第5',
        days: [],
      },
      {
        value: '6',
        label: '最終',
        days: [],
      },
    ],
    listDayOfMonth:[],
  },
  tasks: [{
    id:uuidv4(),
    task_name:'',
    task_content:'',
    listUser:[],
    active:false,
    index:0,
    worker_decision_type:0,
    start_limit_reference_type:2,
    start_limit_reference_task:0,
    start_limit_decision_type:0,
    start_limit_year_diff_value:0,
    start_limit_month_diff_value:0,
    start_limit_day_diff_value:0,
    start_limit_hour_diff_value:0,
    start_limit_minutes_diff_value:0,
    start_limit_year_fixed_value:null,
    start_limit_month_fixed_value:null,
    start_limit_day_fixed_value:null,
    start_limit_hour_fixed_value:null,
    start_limit_minutes_fixed_value:null,
    end_limit_reference_type:0,
    end_limit_reference_task:null,
    end_limit_decision_type:0,
    end_limit_year_diff_value:0,
    end_limit_month_diff_value:0,
    end_limit_day_diff_value:0,
    end_limit_hour_diff_value:0,
    end_limit_minutes_diff_value:0,
    end_limit_year_fixed_value:null,
    end_limit_month_fixed_value:null,
    end_limit_day_fixed_value:null,
    end_limit_hour_fixed_value:null,
    end_limit_minutes_fixed_value:null,
    pre_column1:null
  }],
};

export default createSlice({
  name: 'process',
  initialState: initialState,
  reducers: {
    addNameProcess: (state, action) => {
      const process_name = action.payload
      state.process_name = process_name;
    },
    addTasks: (state, action) => {
      const tasks = [...action.payload]
      state.tasks = tasks;
    },
    editTask: (state, action) => {
      const task = action.payload;
      const index = state.tasks.findIndex((item) => item.id === task.id);
      if (index !== -1) {
        state.tasks[index] = task;
      }
    },
    addTimeProcess: (state, action) => {
      const { triggerStartTime, triggerInterval, triggerType,schedules } = action.payload;
      state.trigger_start_time = triggerStartTime;
      state.trigger_next_execution_start_time = triggerStartTime;
      state.trigger_interval = triggerInterval;
      state.trigger_type = triggerType;
      state.schedules = {...schedules}
    },
    updateProcess: (state, action) => {
      const newProcess = action.payload;
      state.process_id = newProcess.process_id;
    },
    newProcess: (state, action) => {
    if(action.payload){
      const {process_name,trigger_interval,trigger_start_time,trigger_next_execution_start_time,trigger_type,tasks,process_id,team_id,entry_id,newProcess,schedules} = action.payload
      state.process_name = process_name ? process_name : '';
      state.trigger_interval = trigger_interval ? trigger_interval : null;
      state.trigger_start_time = trigger_start_time ? trigger_start_time : null;
      state.trigger_next_execution_start_time = trigger_next_execution_start_time ? trigger_next_execution_start_time : null;
      state.trigger_type = trigger_type ? trigger_type : null;
      state.tasks = tasks ? tasks : [];
      state.process_id = process_id ? process_id : null;
      state.team_id = team_id ? team_id : 1;
      state.entry_id = entry_id ? entry_id : 1;
      state.newProcess = newProcess ? newProcess : false;
      state.schedules={
        listDayOfWeek:schedules?.listDayOfWeek || [],
        listWeekOfMonth:schedules?.listWeekOfMonth || [
          {
            value: '1',
            label: '第1',
            days: [],
          },
          {
            value: '2',
            label: '第2',
            days: [],
          },
          {
            value: '3',
            label: '第3',
            days: [],
          },
          {
            value: '4',
            label: '第4',
            days: [],
          },
          {
            value: '5',
            label: '第5',
            days: [],
          },
          {
            value: '6',
            label: '最終',
            days: [],
          },
        ],
        listDayOfMonth:schedules?.listDayOfMonth || [],
      };
    }
    },
  },
});
