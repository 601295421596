import { Button, Spin } from 'antd';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { FaLightbulb } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { processApi } from '../../../../../api/processApi';
import { tasksApi } from '../../../../../api/tasksApi';
import ModalConfirm from '../../../../../commons/commonComponents/modalConfirm/ModalConfirm';
import { processState, teamState } from '../../../../../redux/selector';
import processSlice from '../../../reduxProcess/processSlice';
import { FormatDateCustom } from '../../../../../commons/commonFunctions/FormatDate';

StepSaveProcess.propTypes = {};

function StepSaveProcess(props) {
  const { nextStep, prevStep, flag } = props;
  const dispatch = useDispatch();
  const processInfo = useSelector(processState);
  const navigate = useNavigate();
  const location = useLocation();
  const teamInfo = useSelector(teamState);
  const [spinning, setSpinning] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const renderStep = () => {
    return (
      <div className='common__process__listtaskbox'>
        <div className='common__flex flex__fd__cl  process__new--intro process__new--save'>
          <p className='color__text__black '>おつかれさまです。</p>
          <p className='color__text__black mb--30'>ここまでの設定で、プロセス定義から「プロセス実行」を行うことができます。</p>
          <p className='color__text__black '>「次へ進む」で引き続き下記の設定が行えます。</p>
          <div style={{ width: 'max-content' }}>
            <p  className='color__text__black ' style={{ textAlign: 'left' }}>自動実行：プロセスを自動実行する条件を設定</p>
            <p className='color__text__black' style={{ textAlign: 'left' }}>遅延通知：管理者/担当者に遅延通知を送信する条件を設定</p>
          </div>

          <p className='color__text__black mb--30'>※担当者の設定で起案者を選択した場合は、自動実行の設定はスキップされます。</p>
          {/* <h2 className='color__title__black'>
            <FaLightbulb />
          </h2> */}
          <p className='color__text__black'>設定を保存する場合は「保存して終了」を押下してください。</p>
          {/* <p className='color__text__black '>実行スケジュールを登録しない場合、</p>
          <p className='color__text__black '>手動でのみ実行出来るプロセスとして保存されます。</p> */}
        </div>
      </div>
    );
  };

  ////////// sử lí trước khi quay về bước trước đó theo
  const handlePrevStep = async () => {
    try {
      if (prevStep) {
        prevStep();
        return;
      }
    } catch (error) {
      setSpinning(false);
      if (error.message) {
        toast.warning(error.message, {
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
      }
    }
  };
  ////////// sử lí trước khi chuyển sang bước tiếp theo
  const handleNextStep = async () => {
    try {
      setSpinning(true);
      let checkUserStart = false;
      const newProcessInfo = { ...processInfo };
      for (let index = 0; index < processInfo.tasks.length; index++) {
        const task = processInfo.tasks[index];
        for (let j = 0; j < task.listUser.length; j++) {
          const user = task.listUser[j];
          if (user.type === '起案者') {
            checkUserStart = true;
            newProcessInfo.checkUserStart = true;
          }
        }
      }
      if (checkUserStart) {
        const action = processSlice.actions.addTimeProcess({ triggerStartTime: null, triggerInterval: null, triggerType: null });
        dispatch(action);
      }
      nextStep();
      return;
    } catch (error) {
      setSpinning(false);
      // if (error.message) {
      //   toast.warning(error.message, {
      //     position: 'top-right',
      //     autoClose: 3000,
      //     closeOnClick: true,
      //     theme: 'light',
      //     pauseOnHover: false,
      //     hideProgressBar: true,
      //   });
      // }
    }
  };

  ////////// luu va thoat
  const handleCreateProcess = async () => {
    try {
      setSpinning(true);
      const userCreate = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

      if (userCreate === null || !userCreate.user_id) {
        navigate('/login');
      }
      const teamId = teamInfo.listTeam[0]?.team_id;
      if (!teamId) {
        toast.warning(
          `まだ何もチームに付属していません！
`,
          {
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          }
        );
        return;
      }
      const newProcessInfo = { 
        ...processInfo, 
        userCreate_id: userCreate.user_id, 
        team_id: teamId, 
        edit_date: new Date().toUTCString(), 
        utc_local: new Date().getUTCHours() + 1,
        new_date: FormatDateCustom(new Date())
      };
      const pathname = location.pathname;
      if (pathname.includes('new') && !pathname.includes('update')) {
        const paramUrl = { process_id: +newProcessInfo.process_id, team_id: processInfo.team_id };
        let checkUserStart = false;
        for (let index = 0; index < processInfo.tasks.length; index++) {
          const task = processInfo.tasks[index];
          for (let j = 0; j < task.listUser.length; j++) {
            const user = task.listUser[j];
            if (user.type === '起案者') {
              checkUserStart = true;
              newProcessInfo.checkUserStart = true;
            }
          }
        }
        if (checkUserStart) {
          newProcessInfo.trigger_start_time = null;
          newProcessInfo.triggerInterval = null;
          newProcessInfo.triggerInterval = null;
        }
        const result = await processApi.create(newProcessInfo);
        if (result?.success) {
          if (result.data?.process_id) {
            const process_id = result.data.process_id;
            const resultSchedule = await processApi.createScheduler({ ...newProcessInfo, process_id });
            let tasks = [];
            const listTask = [...result.data?.listTask] || [];
            for (let index = 0; index < listTask.length; index++) {
              const element = listTask[index];
              if (processInfo.tasks[index].task_name === element.task_name) {
                const taskNew = { ...processInfo.tasks[index] };
                const task_id = element.task_id;
                taskNew.task_id = task_id;
                tasks.push(taskNew);
              }
            }
            const result2 = await tasksApi.updateTask({ ...newProcessInfo, process_id, tasks });
            toast.success(result.success, {
              position: 'top-right',
              autoClose: 3000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: false,
              hideProgressBar: true,
            });
            navigate('/process');
          } else {
            const queryParams = queryString.parse(location.search);
            const result1 = await processApi.getById({ ...queryParams, team_id: teamId });
            if (result1.success) {
              const processInfo = result1.data;
              const result2 = await tasksApi.updateTask({ ...processInfo });
              navigate('/process');
            }
          }
        }
        if (result?.error) {
          toast.warning(result.error, {
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
        }
        setSpinning(false);
        return;
      }
      if (!pathname.includes('new') && pathname.includes('update')) {
        const paramUrl = { process_id: +newProcessInfo.process_id, team_id: processInfo.team_id };
        let checkUserStart = false;
        for (let index = 0; index < processInfo.tasks.length; index++) {
          const task = processInfo.tasks[index];
          for (let j = 0; j < task.listUser.length; j++) {
            const user = task.listUser[j];
            if (user.type === '起案者') {
              checkUserStart = true;
              newProcessInfo.checkUserStart = true;
            }
          }
        }
        if (checkUserStart) {
          const newProcessInfo1 = { ...processInfo };
          delete newProcessInfo1.tasks;
          newProcessInfo1.trigger_start_time = null;
          const result1 = await processApi.createScheduler(newProcessInfo1);
          const action = processSlice.actions.addTimeProcess({ triggerStartTime: null, triggerInterval: null, triggerType: null });
          dispatch(action);
        }
        const result = await processApi.update(paramUrl, newProcessInfo);
        if (result?.success) {
          if (result.data?.process_id) {
            const process_id = result.data.process_id;
            const resultSchedule = await processApi.createScheduler({ ...newProcessInfo, process_id });
            let tasks = [];
            const listTask = [...result.data?.listTask] || [];
            for (let index = 0; index < listTask.length; index++) {
              const element = listTask[index];
              if (processInfo.tasks[index].task_name === element.task_name) {
                const taskNew = { ...processInfo.tasks[index] };
                const task_id = element.task_id;
                taskNew.task_id = task_id;
                tasks.push(taskNew);
              }
            }
            const result2 = await tasksApi.updateTask({ ...newProcessInfo, process_id, tasks });
            navigate('/process');
          } else {
            const queryParams = queryString.parse(location.search);
            const result1 = await processApi.getById({ ...queryParams, team_id: teamId });
            if (result1.success) {
              const processInfo = result1.data;
              const result2 = await tasksApi.updateTask({ ...processInfo });
              const resultSchedule = await processApi.createScheduler({ ...newProcessInfo });
              navigate('/process');
            }
          }
          toast.success(result.success, {
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
        }
        if (result?.error) {
          toast.warning(result.error, {
            position: 'top-right',
            autoClose: 3000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: false,
            hideProgressBar: true,
          });
        }
        setSpinning(false);
        return;
      }
    } catch (error) {
      setSpinning(false);
      if (error.message) {
        toast.warning(error.message, {
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
      }
    } finally {
      setSpinning(false);
    }
  };

  /////////check điều kiện của nút next
  const checkDisableBtn = () => {
    return spinning;
  };

  ////////////huy create process
  const handleCancelConfirm = () => {
    setOpenModal(false);
  };

  const handleShowConfirm = () => {
    setOpenModal(true);
  };

  const resetStart = () => {
    return {
      start_limit_reference_type: 2,
      start_limit_reference_task: 0,

      start_limit_year_diff_value: 0,
      start_limit_month_diff_value: 0,
      start_limit_day_diff_value: 0,
      start_limit_hour_diff_value: 0,
      start_limit_minutes_diff_value: 0,

      start_limit_year_fixed_value: null,
      start_limit_month_fixed_value: null,
      start_limit_day_fixed_value: null,
      start_limit_hour_fixed_value: null,
      start_limit_minutes_fixed_value: null,
    };
  };
  const resetEnd = () => {
    return {
      end_limit_reference_type: 0,
      end_limit_reference_task: 0,
      end_limit_decision_type: 0,

      end_limit_year_diff_value: 0,
      end_limit_month_diff_value: 0,
      end_limit_day_diff_value: 0,
      end_limit_hour_diff_value: 0,
      end_limit_minutes_diff_value: 0,

      end_limit_year_fixed_value: null,
      end_limit_month_fixed_value: null,
      end_limit_day_fixed_value: null,
      end_limit_hour_fixed_value: null,
      end_limit_minutes_fixed_value: null,
    };
  };
  const handleRemoveOptionTask = (item) => {
    const resetStartTask = resetStart();
    const resetEndTask = resetEnd();
    const newtask = { ...item, ...resetStartTask, ...resetEndTask };
    newtask.start_limit_reference_type = +item.start_limit_reference_type === 1 ? 2 : +item.start_limit_reference_type;
    newtask.end_limit_reference_type = +item.end_limit_reference_type === 1 ? 0 : +item.end_limit_reference_type;
    newtask.end_limit_decision_type = +item.end_limit_reference_type === 0 && +item.end_limit_decision_type === 1 ? 1 : 0;
    const action = processSlice.actions.editTask({ ...newtask });
    dispatch(action);
    return;
  };
  useEffect(() => {
    if (!processInfo.trigger_start_time) {
      const listTask = processInfo.tasks;
      for (let index = 0; index < listTask.length; index++) {
        const element = listTask[index];
        handleRemoveOptionTask(element);
      }
    }
  }, []);

  return (
    <>
      <div className='process__content__bottom--box'>
        <div className='process__new--content '>
          <Spin spinning={spinning} size='large'>
            {renderStep()}
          </Spin>
        </div>
        <div className='common__process__control'>
          <div className='common__flex common__process__control--listbtn'>
            <div className='common__flex common__process__control--btnL'>
              <Button disabled={checkDisableBtn()} className={checkDisableBtn() ? 'common__process__bottom--btn--disable common__process__control--btn' : 'bg__red common__process__control--btn'} onClick={handleCreateProcess}>
                保存して終了
              </Button>
            </div>
            <div className='common__flex common__process__control--btnR'>
              <div className='flex__box flex__jus__sb flex__gap__cl--20'>
                <Button disabled={checkDisableBtn()} className={checkDisableBtn() ? 'common__process__bottom--btn--disable common__process__control--btn' : 'bg__blue common__process__control--btn'} onClick={handlePrevStep}>
                  前に戻る
                </Button>

                <Button disabled={checkDisableBtn()} className={checkDisableBtn() ? 'common__process__bottom--btn--disable common__process__control--btn' : 'bg__green common__process__control--btn'} onClick={handleNextStep}>
                  次へ進む
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StepSaveProcess;
