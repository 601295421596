import { Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalConfirm from '../../../../../commons/commonComponents/modalConfirm/ModalConfirm';
import { escapeRegExp } from '../../../../../commons/commonFunctions/CommonFunction';
import { processState } from '../../../../../redux/selector';
import processSlice from '../../../reduxProcess/processSlice';
import queryString from 'query-string';

StepCreateTaskContent.propTypes = {};

function StepCreateTaskContent(props) {
  const { nextStep, prevStep, flag } = props;
  const dispatch = useDispatch();
  const processInfo = useSelector(processState);
  const navigate = useNavigate();
  const location = useLocation();
  const [spinning, setSpinning] = useState(false);
  const [step, setStep] = useState(() => {
    return flag ? 0 : 0;
  });
  const [openModal, setOpenModal] = useState(false);
  const [textTaskContent, setTextTaskContent] = useState(() => {});

  const [listTask, setListTask] = useState(() => {
    return processInfo.taskList || [];
  });

  const [taskInfo, setTaskInfo] = useState({});

  const renderStep = (step) => {
    switch (step) {
      case 0:
        return (
          <div className='process__new--intro common__process__listtaskbox'>
            <div className='process__new--intro '>
              <h2 className='color__title__black mt--30'>各タスクに指示内容の設定を行います。</h2>
              <h2 className='mb--30 color__title__black'>設定するタスクを選択してください。</h2>
              <div className='common__process__listtask  mb--30'>
                <div className='common__flex process__listtask'>
                  {listTask.length > 0 &&
                    listTask.map((item) => {
                      return (
                        <div className='common__flex process__task--item' key={item.id}>
                          <div className='common__flex process__task--itemL'>
                            <div className='process__task--item--number'>
                              <p>タスク</p>
                              <p>{item.index + 1}</p>
                            </div>
                            <div className='common__flex process__task--item--name'>
                              <div className='common__flex process__task--item--input'>{item.task_name}</div>
                            </div>
                          </div>
                          <div className='process__task--itemR'>
                            <Button
                              className={item?.task_content !== '' ? 'bg__mid__blue process__task--itemR--btn' : 'process__task--itemR--btn'}
                              onClick={() => {
                                setStep(step + 1);
                                setTaskInfo({ ...item });
                                setTextTaskContent(item.task_content);
                              }}
                            >
                              {item?.task_content !== '' ? '変更' : '設 定'}
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className='process__new--intro common__process__listtaskbox'>
            <div className='common__flex flex__fd__cl process__new--intro '>
              <h2 className='color__title__black mt--30'>
                タスク {taskInfo.index + 1}「{taskInfo.task_name}」
              </h2>
              <div style={{ width: 'max-content' }}>
                <p className='color__text__black' style={{ textAlign: 'left' }}>
                  この画面の指示内容が担当者にメールで通知されます。
                </p>
                <p className='color__text__black' style={{ textAlign: 'left' }}>
                  各担当者が指示内容を確認し、作業後に完了報告を行います。
                </p>
              </div>
            </div>
            <div className='process__new--contentask--input mt--30'>
              <p className='' style={{color:'#000',fontWeight:600}}>指示内容を具体的に記入してください。</p>
              <TextArea
                maxLength={3001}
                onChange={(e) => {
                  const task_content = e.target.value;
                  const nameEscapeRegExp = escapeRegExp(task_content);
                  if (nameEscapeRegExp.length <= 3000 && !toast.isActive({ toastId: 'length_content_task' })) {
                    setTextTaskContent(nameEscapeRegExp);
                  } else {
                    toast.warning('3000字以内で入力してください。', {
                      toastId: 'length_content_task',
                      position: 'top-right',
                      autoClose: 3000,
                      closeOnClick: true,
                      theme: 'light',
                      pauseOnHover: false,
                      hideProgressBar: true,
                    });
                  }
                }}
                value={textTaskContent}
                type='Text'
                placeholder={`１．○○○○○○○○○○
２．○○○○○○○○○○
３．○○○○○○○○○○
４．完了したら完了報告ボタンを押してください。`}
              />
            </div>
          </div>
        );
      // case 2:
      //   return (
      //     <div className='common__process__new--intro common__process__new--contentask'>
      //       <div className='flex__box flex__jus__c flex__fd__cl mb--30'>
      //         <h2 className=''>これでプロセスとタスクの設定は終了となります。</h2>
      //         <h2 className=''>このままプロセスの実行スケジュールを登録を続けるか、</h2>
      //         <h2 className=''>保存して終了かを選んでください。</h2>
      //       </div>
      //     </div>
      //   );
      default:
        return null;
    }
  };

  ////////// sử lí trước khi quay về bước trước đó theo
  const handlePrevStep = async () => {
    try {
      if (step > 0 && step < 2) {
        setStep(step - 1);
        return;
      }
      if (prevStep && step === 0) {
        prevStep();
        return;
      }
    } catch (error) {
      setSpinning(false);
      if (error.message) {
        toast.warning(error.message, {
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
      }
    }
  };
  ////////// sử lí trước khi chuyển sang bước tiếp theo
  const handleNextStep = async () => {
    try {
      const check = checkDisableBtn();
      if (step === 0 && check) {
        setStep(step + 1);
        return;
      }
      if (step === 0 && !check) {
        nextStep();
        return;
      }
      if (step === 1) {
        const newTask = { ...taskInfo };
        newTask.task_content = textTaskContent;
        const action = processSlice.actions.editTask(newTask);
        dispatch(action);
        setTaskInfo({});
        setStep(step - 1);
        return;
      }
    } catch (error) {
      setSpinning(false);
      if (error.message) {
        toast.warning(error.message, {
          position: 'top-right',
          autoClose: 3000,
          closeOnClick: true,
          theme: 'light',
          pauseOnHover: false,
          hideProgressBar: true,
        });
      }
    }
  };

  /////////check điều kiện của nút next
  const checkDisableBtn = () => {
    if (step === 0) {
      const check = listTask.filter((item) => {
        return item.listUser ? (item.task_content === '' ? true : false) : false;
      });
      return check.length > 0 ? true : false;
    }
    if (step === 1) {
      const check = textTaskContent === '' ? true : false;
      return check;
    }
    if (step === 2 && spinning) {
      return spinning;
    }
  };

  ////////////////
  useEffect(() => {
    setListTask(() => {
      return processInfo.tasks || [];
    });
  }, [processInfo]);

  ////////////huy create process
  const handleCancelCreateProcess = () => {
    const searchParam = queryString.parse(location.search);
    const action = processSlice.actions.newProcess({});
    dispatch(action);
    if(searchParam.process_id){
      navigate({
        pathname: `/process`,
      });
    }else{
      navigate({
        pathname: `/template`,
      });
    }
    return;
    // localStorage.removeItem('process');
    // const action = processSlice.actions.newProcess({});
    // dispatch(action);
    // setOpenModal(false);
    // navigate('/process');
    // return;
  };
  const handleCancelConfirm = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className='process__content__bottom--box'>
        <div className='process__new--content process__new--content--custom'>{renderStep(step)}</div>
        <div className='common__process__control'>
          <div className='common__flex common__process__control--listbtn'>
            <div className='common__flex common__process__control--btnL'>
              <>
                <Button
                  className={step === 0 ? `bg__red common__process__control--btn` : `bg__blue common__process__control--btn`}
                  onClick={() => {
                    if (step === 1) {
                      setStep(0);
                    }
                    if (step === 0) {
                      setOpenModal(true);
                    }
                  }}
                >
                  {step === 0 ? 'キャンセル' : ' 前に戻る'}
                </Button>
                <ModalConfirm open={openModal} handleCancelConfirm={handleCancelConfirm} handleSubmit={handleCancelCreateProcess} textConfirm={`現在設定中の内容は破棄されます。`} textConfirmMid={`設定を終了してよろしいですか？`} />
              </>
            </div>
            <div className='common__flex common__process__control--btnR'>
              {step === 0 && (
                <>
                  <Button disabled={false ? true : false} className={false ? 'common__process__bottom--btn--disable common__process__control--btn' : 'bg__blue common__process__control--btn'} onClick={handlePrevStep}>
                    前に戻る
                  </Button>
                  <Button disabled={checkDisableBtn()} className={checkDisableBtn() ? 'common__process__bottom--btn--disable common__process__control--btn' : 'bg__green common__process__control--btn'} onClick={handleNextStep}>
                    次へ進む
                  </Button>
                </>
              )}
              {step === 1 && (
                <>
                  <Button disabled={checkDisableBtn()} className={checkDisableBtn() ? 'common__process__bottom--btn--disable common__process__control--btn' : 'bg__green common__process__control--btn'} onClick={handleNextStep}>
                    内容を確定
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StepCreateTaskContent;
