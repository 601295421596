import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, DatePicker, Image, Modal, Row, Spin, TimePicker, Tooltip } from 'antd';
import queryString from 'query-string';
import logo from '../../assets/logo.jpg';
import './Css/style.css';
import { trn_tasksApi } from '../../api/trn_tasksApi';
import { toast, ToastContainer } from 'react-toastify';
import { formatDateJP } from '../../commons/commonFunctions/FormatDate';
import { convertToFull } from '../../commons/commonFunctions/CommonFunction';
import moment from 'moment';
import { FaCalendarAlt, FaCaretDown } from 'react-icons/fa';

TaskDueDate.propTypes = {};

function TaskDueDate(props) {
  const [process, setProcess] = useState(null);
  const [data, setData] = useState([]);
  const [spin, setSpin] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [openDone, setOpenDone] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openNotUser, setOpenNotUser] = useState(false);
  const [openNotFound, setOpenNotFound] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [fontSize, setFontSize] = useState(0);
  const [errorTasks, setErrorTasks] = useState([]);
  const location = useLocation();
  const navigation = useNavigate();
  const teamInfo = localStorage.getItem('teamInfo') ? JSON.parse(localStorage.getItem('teamInfo')) : [];

  const handleSubmit = async () => {
    try {
      setSpin(true);

      if (errorTasks && errorTasks.length > 0) {
        for (let index = errorTasks.length - 1; index > 0; index--) {
          const element = errorTasks[index];
          toast.error(`タスク${element}の設定情報が不足しています。`, {
            toastId: `toastId_err${index}`,
            position: 'top-right',
            autoClose: 5000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: true,
            hideProgressBar: true,
          });
        }
        setSpin(false);
      } else {
        const result = await trn_tasksApi.updateTaskDueDateRespond(+process.trn_manage_id, data);
        if (result.success) {
          toast.success('正常に登録されました。', {
            position: 'top-right',
            autoClose: 5000,
            closeOnClick: true,
            theme: 'light',
            pauseOnHover: true,
            hideProgressBar: true,
          });
          setSpin(false);
          setOpenNew(false);
          setOpenDone(true);
        } else {
          if (result.error_no === 1 && !toast.isActive({ toastId: 'toastId_err' })) {
            setSpin(false);
            toast.error(result.error, {
              toastId: 'toastId_err',
              position: 'top-right',
              autoClose: 5000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: true,
              hideProgressBar: true,
            });
            setOpenNew(false);
            setOpenNotUser(true);
          } else {
            setSpin(false);
            toast.error(result.error, {
              toastId: 'toastId_err',
              position: 'top-right',
              autoClose: 5000,
              closeOnClick: true,
              theme: 'light',
              pauseOnHover: true,
              hideProgressBar: true,
            });
            setOpenNew(false);
            setOpenError(true);
          }
        }
      }
    } catch (error) {
      setSpin(false);
      console.log(error);
    }
  };

  const handleChange = (task_no, e, isStart, isDate) => {
    if (e) {
      let dataInclude = data.filter((x) => x.task_no === task_no);
      if (dataInclude && dataInclude.length > 0) {
        let dataExclude = data.filter((x) => x.task_no !== task_no);
        const dateNow = moment().seconds(0).milliseconds(0);
        const dateInput = moment(e._d).seconds(0).milliseconds(0);
        const value = dateInput?.toDate();
        if (isStart) {
          if (dateInput){
            if(dateInput < dateNow && !toast.isActive({ toastId: 'toastId_err' })) {
              toast.warning('現在日時より前の日時が入力されています。ご確認ください。', {
                toastId: 'toastId_err',
                position: 'top-right',
                autoClose: 5000,
                closeOnClick: true,
                theme: 'light',
                pauseOnHover: true,
                hideProgressBar: true,
              });
            }
            let changeValue = dataInclude[0].task_start_limit ? new Date(dataInclude[0].task_start_limit) : new Date();
            if (isDate) {
              changeValue.setFullYear(value.getFullYear());
              changeValue.setMonth(value.getMonth());
              changeValue.setDate(value.getDate());
            } else {
              changeValue.setHours(value.getHours());
              changeValue.setMinutes(value.getMinutes());
              changeValue.setSeconds(0);
            }
            dataInclude[0].task_start_limit = changeValue.toJSON();
            dataInclude[0].update_status = '1';
          }
        } else {
          if (dateInput){
            if(dateInput < dateNow && !toast.isActive({ toastId: 'toastId_err' })) {
              toast.warning('現在日時より前の日時が入力されています。ご確認ください。', {
                toastId: 'toastId_err',
                position: 'top-right',
                autoClose: 5000,
                closeOnClick: true,
                theme: 'light',
                pauseOnHover: true,
                hideProgressBar: true,
              });
            }
            let changeValue = dataInclude[0].task_end_limit ? new Date(dataInclude[0].task_end_limit) : new Date();
            if (isDate) {
              changeValue.setFullYear(value.getFullYear());
              changeValue.setMonth(value.getMonth());
              changeValue.setDate(value.getDate());
              // changeValue.setHours(value.getHours());
              // changeValue.setMinutes(value.getMinutes());
            } else {
              changeValue.setHours(value.getHours());
              changeValue.setMinutes(value.getMinutes());
              changeValue.setSeconds(0);
            }
            dataInclude[0].task_end_limit = changeValue.toJSON();
            dataInclude[0].update_status = '1';
          }
        }
        let dataMerge = dataExclude.concat(dataInclude);
        dataMerge.sort(function (a, b) {
          return a.task_no - b.task_no;
        });
        setData(dataMerge);
      }
    } else {
      let dataInclude = data.filter((x) => x.task_no === task_no);
      if (dataInclude && dataInclude.length > 0) {
        let dataExclude = data.filter((x) => x.task_no !== task_no);

        if (isStart) {
          dataInclude[0].task_start_limit = null;
          dataInclude[0].update_status = '1';
        } else {
          dataInclude[0].task_end_limit = null;
          dataInclude[0].update_status = '1';
        }
        let dataMerge = dataExclude.concat(dataInclude);
        dataMerge.sort(function (a, b) {
          return a.task_no - b.task_no;
        });
        setData(dataMerge);
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      const accessToken = localStorage.getItem('accessToken');
      const user = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
      if (!accessToken || !user) {
        navigation('/login');
        return;
      }
      var filter = queryString.parse(location.search);
      if (filter.trn_manage_id || filter.task_id || filter.team_id) {
        try {
          const param = { trn_manage_id: filter.trn_manage_id, user_id: user.user_id };
          const result = await trn_tasksApi.getTaskDueDate(param);
          if (result.data) {
            if (result.data.data.length > 0) {
              setData(result.data.data);
              const search_status = result.data.search_status;
              const process_name = teamInfo.length <= 0 || teamInfo[0].team_id != result.data.data[0].team_id ? '' : result.data.data[0].process_name;
              const process_start_achievement = teamInfo.length <= 0 || teamInfo[0].team_id != result.data.data[0].team_id ? '' : formatDateJP(new Date(result.data.data[0].process_start_achievement));
              if (search_status === 0) {
                setProcess({
                  trn_manage_id: result.data.data[0].trn_manage_id,
                  process_name: process_name,
                  process_start_achievement: process_start_achievement,
                });
                setOpenNew(true);
              } else if (search_status === -1) {
                setProcess({});
                setOpenNotUser(true);
              } else if (search_status === -2) {
                setProcess({});
                setOpenNotFound(true);
              }
            } else {
              setData([]);
              setProcess({});
              setOpenNotUser(true);
            }
          } else {
            setData([]);
            setProcess({});
            setOpenNotUser(true);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setData([]);
        setProcess({});
        setOpenNotUser(true);
        window.close();
      }
    }
    fetchData();
  }, [location.pathname]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    let errors = [];
    for (let i = 0; i < data.length; i++) {
      let task = data[i];
      let check_start = task.task_no == 1 || task.task_start_limit_mode != 1 ? !task.task_end_limit && task.task_end_limit_mode == 1 : !task.task_start_limit && task.task_start_limit_mode == 1;
      let check_end = task.task_no > 1 && task.task_end_limit_mode != 1 ? !task.task_start_limit && task.task_start_limit_mode == 1 : !task.task_end_limit && task.task_end_limit_mode == 1;
      if ((check_start && !check_end) || (!check_start && check_end)) {
        errors.push(task.task_no);
      }
      // else{
      //   if(task.task_start_limit && task.task_end_limit && task.task_start_limit > task.task_end_limit){
      //     errors.push(task.task_no);
      //   }
      // }
    }
    setErrorTasks(errors);
  }, [data]);

  useEffect(() => {
    setFontSize(windowWidth / 40);
  }, [windowWidth, windowHeight]);

  return (
    <div className='App'>
     
     <ToastContainer />
      <Modal
      className='task_duedate'
        open={openNew}
        title={
          <Row>
            <Col span={2}>
              <Image height={50} src={logo} preview={false} />
            </Col>
            <Col span={10} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'left', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
            <span className='common_text-clamp common_text-clamp--2' style={{lineHeight:`${fontSize / 1.2}px`}}> プロセス名：{process ? process.process_name : ''}</span>
            {/* <Tooltip color={'#00a199'} placement='topLeft' title={process?.process_name} arrowPointAtCenter>
                <div className='flex__box'> <span style={{width:"max-content"}}>プロセス名：</span><span className='common_text-clamp common_text-clamp--1' style={{marginBottom:0,flex:1,overflow:'hidden'}}>{process ? process.process_name : ''}</span></div>
            </Tooltip> */}
            </Col>
            <Col span={12} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'right', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
              【プロセス開始日時{process ? process.process_start_achievement : ''}】
            </Col>
          </Row>
        }
        centered={true}
        closable={false}
        //height={"80%"}
        width={'100%'}
        header={''}
        footer={null}
      >
        <Spin tip='ローディング．．．' spinning={spin}>
          <div className='task_content'>
            <Row style={{ padding:'20px 0px' }}>
              <Col span={2}></Col>
              <Col span={20}>
                <div className='text-clamp text-clamp--2'>
                  <p style={{  color: '#fff', textAlign: 'center', fontSize: `${fontSize / 1.5}px` }}>
                    開始時設定を行ってください。<br/>
                    変更はタスクが開始されるまで行うことができます。
                  </p>
                  <Row>
                    <Col span={6}></Col>
                    <Col span={18}>
                      <div style={{ color: '#fff', textAlign: 'left', fontSize: `${fontSize / 2.5}px` }}>
                        ※遅延通知が既に送信されている場合は、変更後の日時で再送信されます。<br/>
                        ※変更されたことをお知らせする通知は送信されませんので、ご注意ください。
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={2}></Col>
            </Row>
            <Row style={{ paddingBottom: 10, border: '3px solid', borderColor: 'white', borderStyle: '' }}>
              <Col span={2}></Col>
              <Col span={20}>
                <div className='flex__box flex__jus__sb flex__fd__cl flex__gap__r--20'>
                  <Row>
                    <Col span={4}></Col>
                    <Col span={10}>
                      <div style={{ textAlign: 'center', color: 'white', fontSize: `${fontSize / 2}px` }}>開始予定日時</div>
                    </Col>
                    <Col span={10}>
                      <div style={{ textAlign: 'center', color: 'white', fontSize: `${fontSize / 2}px` }}>終了予定日時</div>
                    </Col>
                  </Row>
                  {data.length > 0 &&
                    data.map((item,index) => {
                      return (
                        <div key={index}>
                          <Row gutter={[10, 10, 10, 10]}>
                            <Col span={4}>
                              <div className='flex__box flex__jus__sb flex--1 process__task__box'>
                                <div className='process__task__box--number' style={{width:"100%", textAlign: 'left', fontSize: `${fontSize / 3}px`,flex:1 }}>
                                  タスク{convertToFull(item.task_no)}
                                  <Tooltip color={'#00a199'} placement='topLeft' title={item.task_name} arrowPointAtCenter>
                                   <div className='flex__box'> <span style={{width:"max-content"}}>タスク名：</span><span className='common_text-clamp common_text-clamp--1' style={{marginBottom:0,flex:1,overflow:'hidden'}}>{item ? item.task_name : ''}</span></div>
                                  </Tooltip>
                                </div>
                              </div>
                            </Col>
                            <Col span={10}>
                              <div className='flex__box flex__jus__sb flex--1 process__task__box'>
                                <div className='process__task__box--nametask flex--1'>
                                  {item.task_no == 1 && (
                                    <p style={{ textAlign: 'left', fontSize: `${fontSize / 3}px`,margin:0 }}>
                                      プロセス開始日時
                                      <br />
                                      {process ? process.process_start_achievement + ' にタスクが開始' : ''}
                                    </p>
                                  )}
                                  {item.task_no > 1 && item.task_start_limit_mode_display == 0 && (
                                    <p style={{ textAlign: 'left', fontSize: `${fontSize / 3}px` }}>
                                      設定なし
                                      <br />　
                                    </p>
                                  )}
                                  {item.task_no > 1 && item.task_start_limit_mode_display == 1 && (item.status == 1 || item.status == 2 || item.status == 3 || item.status == 4) && (
                                    <div style={{ textAlign: 'left', fontSize: `${fontSize / 3}px` }}>
                                     <p style={{margin:0}}> 開始時設定 </p>
                                      <Row>
                                        <Col span={8}>
                                          <DatePicker
                                            value={item.task_start_limit !== null ? moment(item.task_start_limit) : ''}
                                            allowClear={true}
                                            suffixIcon={<FaCalendarAlt size={20} color='#000' />}
                                            format={'yyyy 年 MM 月 DD'}
                                            style={{ fontSize: `${fontSize / 2.5}px` }}
                                            onChange={(e) => {
                                              handleChange(item.task_no, e, true, true);
                                            }}
                                          />
                                        </Col>
                                        <Col span={8}>
                                          <TimePicker
                                            showNow={false}
                                            value={item.task_start_limit !== null ? moment(item.task_start_limit) : ''}
                                            allowClear={false}
                                            suffixIcon={<FaCaretDown size={20} color='#000' />}
                                            format={'HH：mm'}
                                            style={{ fontWeight: 600, fontSize: `${fontSize / 2.5}px` }}
                                            onSelect={(e) => {
                                              handleChange(item.task_no, e, true, false);
                                            }}
                                          />
                                        </Col>
                                        <Col span={8}>
                                          <div style={{height:"100%",display:'flex',alignItems:'center',justifyContent:"center"}}>
                                            <p>にタスクが開始</p>
                                          </div>
                                      </Col>
                                      </Row>
                                    </div>
                                  )}
                                  {item.task_no > 1 && item.task_start_limit_mode_display == 1 && item.status != 1 && item.status != 2 && item.status != 3 && item.status != 4 && (
                                    <div style={{ textAlign: 'left', fontSize: `${fontSize / 3}px` }}>
                                      開始時設定 <br />
                                      {item.task_start_limit ? formatDateJP(new Date(item.task_start_limit)) + '　にタスクが開始' : <br />}
                                    </div>
                                  )}
                                  {item.task_no > 1 && item.task_start_limit_mode_display == 2 && (
                                    <div style={{ textAlign: 'left', fontSize: `${fontSize / 3}px` }}>
                                      事前設定 <br />
                                      {item.task_start_limit ? formatDateJP(new Date(item.task_start_limit)) + '　にタスクが開始' : <br />}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col span={10}>
                              <div className='flex__box flex__jus__sb flex--1 process__task__box'>
                                <div className='process__task__box--nametask flex--1'>
                                  {item.task_end_limit_mode_display == 0 && (
                                    <p style={{ textAlign: 'left', fontSize: `${fontSize / 3}px` }}>
                                      設定なし
                                      <br />　
                                    </p>
                                  )}
                                  {item.task_end_limit_mode_display == 1 && (item.status == 1 || item.status == 2 || item.status == 3 || item.status == 4) && (
                                    <div style={{ textAlign: 'left', fontSize: `${fontSize / 3}px` }}>
                                      <p style={{margin:0}}>開始時設定</p> 
                                      <Row>
                                        <Col span={8}>
                                      <DatePicker
                                        value={item.task_end_limit !== null ? moment(item.task_end_limit) : ''}
                                        allowClear={true}
                                        suffixIcon={<FaCalendarAlt size={20} color='#000' />}
                                        format={'yyyy 年 MM 月 DD'}
                                        style={{ fontSize: `${fontSize / 2.5}px` }}
                                        onChange={(e) => {
                                          handleChange(item.task_no, e, false, true);
                                        }}
                                      />
                                      </Col>
                                      <Col span={8}>
                                      <TimePicker
                                        showNow={false}
                                        value={item.task_end_limit !== null ? moment(item.task_end_limit) : ''}
                                        allowClear={false}
                                        suffixIcon={<FaCaretDown size={20} color='#000' />}
                                        format={'HH：mm'}
                                        style={{ color: 'black', fontWeight: 600, fontSize: `${fontSize / 2.5}px` }}
                                        onSelect={(e) => {
                                          handleChange(item.task_no, e, false, false);
                                        }}
                                      />
                                      </Col>
                                      <Col span={8}>
                                          <div style={{height:"100%",display:'flex',alignItems:'center',justifyContent:"center"}}>
                                          　<p>にタスクが終了</p>
                                      </div>
                                      </Col>
                                      </Row>
                                        
                                    </div>
                                  )}
                                  {item.task_end_limit_mode_display == 1 && item.status != 1 && item.status != 2 && item.status != 3 && item.status != 4 && (
                                    <div style={{ textAlign: 'left', fontSize: `${fontSize / 3}px` }}>
                                      開始時設定 <br />
                                      {item.task_end_limit ? formatDateJP(new Date(item.task_end_limit)) + '　にタスクが終了' : <br />}
                                    </div>
                                  )}
                                  {item.task_end_limit_mode_display == 2 && (
                                    <div style={{ textAlign: 'left', fontSize: `${fontSize / 3}px` }}>
                                      事前設定 <br />
                                      {item.task_end_limit ? formatDateJP(new Date(item.task_end_limit)) + '　にタスクが終了' : <br />}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                </div>
              </Col>
              <Col span={2}></Col>
            </Row>
            <Row style={{ minHeight: 50, padding: 20 }}>
              <div className='flex__box flex__jus__c ' style={{width:'100%'}}>
                <div className='flex__box flex__jus__sb common__process__bottom--btn'>
                  <Button style={{ backgroundColor: 'transparent' }}></Button>
                  <div className='flex__box flex__jus__sb flex__gap__cl--20'>
                    <Button
                      // disabled={errorTasks?.length > 0}
                      size='large'
                      className='bg__green common__process__control--btn'
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      内容を確定
                    </Button>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Spin>
      </Modal>

      <Modal
        open={openDone}
        title={
          <Row>
            <Col span={2}>
              <Image height={50} src={logo} preview={false} />
            </Col>
            <Col span={10} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'left', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
            <span className='common_text-clamp common_text-clamp--2' style={{lineHeight:`${fontSize / 1.2}px`}}>プロセス名：{process ? process.process_name : ''}</span>
            </Col>
            <Col span={12} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'right', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
              【プロセス開始日時{process ? process.process_start_achievement : ''}】
            </Col>
          </Row>
        }
        centered={true}
        closable={false}
        width={'100%'}
        header={''}
        footer={null}
      >
        <div className='task_content'>
          <Row>
            <Col span={2}></Col>
            <Col span={20}>
              <div className='task_content--message'>
                <div style={{paddingTop: 50, fontSize:`${fontSize / 2}px`, textAlign: "center"}} className="text-clamp text-clamp--2">
                  開始時設定の内容を確定しました。<br/>
                  このウィンドウは閉じてください。
                </div>
              </div>
            </Col>
            <Col span={2}></Col>
          </Row>
        </div>
      </Modal>

      <Modal
        open={openError}
        title={
          <Row>
            <Col span={2}>
              <Image height={50} src={logo} preview={false} />
            </Col>
            <Col span={10} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'left', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
            <span className='common_text-clamp common_text-clamp--2' style={{lineHeight:`${fontSize / 1.2}px`}}>プロセス名：{process ? process.process_name : ''}</span>
            </Col>
            <Col span={12} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'right', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
              【プロセス開始日時{process ? process.process_start_achievement : ''}】
            </Col>
          </Row>
        }
        centered={true}
        closable={false}
        width={'100%'}
        header={''}
        footer={null}
      >
        <div className='task_content'>
          <Row>
            <Col span={2}></Col>
            <Col span={20}>
              <div className='task_content--message'>
                <p style={{ paddingTop: 50, fontSize: `${fontSize / 2}px` }} className='text-clamp text-clamp--2'>
                  設定中に開始されたタスクが存在するため、内容を確定できませんでした。
                </p>
              </div>
            </Col>
            <Col span={2}></Col>
          </Row>
        </div>
      </Modal>

      <Modal
        open={openNotUser}
        title={
          <Row>
            <Col span={2}>
              <Image height={50} src={logo} preview={false} />
            </Col>
            <Col span={10} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'left', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
            <span className='common_text-clamp common_text-clamp--2' style={{lineHeight:`${fontSize / 1.2}px`}}>プロセス名：{process ? process.process_name : ''}</span>
            </Col>
            <Col span={12} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'right', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
              【プロセス開始日時{process ? process.process_start_achievement : ''}】
            </Col>
          </Row>
        }
        centered={true}
        closable={false}
        width={'100%'}
        header={''}
        footer={null}
      >
        <div className='task_content'>
          <Row>
            <Col span={2}></Col>
            <Col span={20}>
              <div className='task_content--message'>
                <div style={{paddingTop: 50, fontSize:`${fontSize / 2}px`, textAlign: "center"}} className="text-clamp text-clamp--2">
                  開始時設定を行う権限がありません。<br/>
                  このウィンドウは閉じてください。
                </div>
              </div>
            </Col>
            <Col span={2}></Col>
          </Row>
        </div>
      </Modal>

      <Modal
        open={openNotFound}
        title={
          <Row>
            <Col span={2}>
              <Image height={50} src={logo} preview={false} />
            </Col>
            <Col span={10} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'left', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
            <span className='common_text-clamp common_text-clamp--2' style={{lineHeight:`${fontSize / 1.2}px`}}>プロセス名：{process ? process.process_name : ''}</span>
            </Col>
            <Col span={12} style={{ paddingTop: 10, paddingLeft: 10, textAlign: 'right', fontWeight: 'bold', fontSize: `${fontSize / 1.5}px` }}>
              【プロセス開始日時{process ? process.process_start_achievement : ''}】
            </Col>
          </Row>
        }
        centered={true}
        closable={false}
        width={'100%'}
        header={''}
        footer={null}
      >
        <div className='task_content'>
          <Row>
            <Col span={2}></Col>
            <Col span={20}>
              <div className='task_content--message'>
                <p style={{ paddingTop: 50, fontSize: `${fontSize / 2}px` }} className='text-clamp text-clamp--2'>
                  設定中に開始されたタスクが存在するため、内容を確定できませんでした。
                </p>
              </div>
            </Col>
            <Col span={2}></Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
}

export default TaskDueDate;
