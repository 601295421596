import { Layout, Spin } from 'antd';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { FaCog } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { processState, teamState } from '../../../../redux/selector';
import ListStepComponent from './components/ListStep';
import StepCreateManagerTask from './components/StepCreateManagerTask';
import StepCreateProcess from './components/StepCreateProcess';
import StepCreateProcessScheduler from './components/StepCreateProcessScheduler';
import StepCreateTaskContent from './components/StepCreateTaskContent';
import StepCreateTaskDuedate from './components/StepCreateTaskDuedate';
// import './css/style.css';
import { toast } from 'react-toastify';
import { processApi } from '../../../../api/processApi';
import '../../css/style.css';
import processSlice from '../../reduxProcess/processSlice';
import StepSaveProcess from './components/StepSaveProcess';
import StepCreateNotification from './components/StepCreateNotification';
const { Header, Content, Footer } = Layout;
ProcessNew.propTypes = {};
const ListStepInitial = [
  { id: 1, name: 'タスク名', active: true },
  { id: 2, name: '担当者', active: false },
  { id: 3, name: '指示内容', active: false },
  { id: 4, name: '指示通知', active: false },
  { id: 5, name: '自動実行', active: false },
  { id: 6, name: '遅延通知', active: false },
];

function ProcessNew(props) {
  const processInfo = useSelector(processState);
  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const teamInfo = useSelector(teamState);
  const [spinning, setSpinning] = useState(false);
  const [hiddenListStep, setHiddenListStep] = useState(false);
  const [step, setStep] = useState(1);
  const [listStep, setListStep] = useState(() => {
    return ListStepInitial.map((item) => {
      return { ...item };
    });
  });
  const [flag, setFlag] = useState(false);
  ////////// handle ẩn list step
  const handleListStep = (step) => {
    if (step < 2) {
      setHiddenListStep(true);
    }

    if (step > 1) {
      setHiddenListStep(false);
    }
  };


//////////////phase 2///////////////
  const handleNextStep = () => {
    try {
      const tasks = processInfo.tasks;
      let currentStep = step;
      let check = false;
      for (let index = 0; index < tasks.length; index++) {
        const task = tasks[index];
        for (let j = 0; j < task.listUser.length; j++) {
          const user = task.listUser[j];
          if (user?.type === '起案者') {
            check = true;
          }
        }
      }
      currentStep = currentStep + 1;
      if (step <= 4) {
        setStep(currentStep);
        setFlag(false);

        if (step !== 4) {
          const newListStep = listStep.map((item) => {
            if (item.id === currentStep) {
              item.active = true;
            } else {
              item.active = false;
            }
            return item;
          });
          setListStep(newListStep);
        }
      }
      if (step >= 5 && check) {
        setStep(currentStep + 1);
        setFlag(false);
        const newListStep = listStep.map((item) => {
          if (item.id === currentStep) {
            item.active = true;
          } else {
            item.active = false;
          }
          return item;
        });
        setListStep(newListStep);
        return;
      }
      if (step >= 5 && !check) {
        setStep(currentStep);
        setFlag(false);
        const newListStep = listStep.map((item) => {
          if (item.id === step) {
            item.active = true;
          } else {
            item.active = false;
          }
          return item;
        });
        setListStep(newListStep);
        return;
      }
      setHiddenListStep(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handlePrevStep = () => {
    try {
      const tasks = processInfo.tasks;
      let currentStep = step;
      let check = false;
      for (let index = 0; index < tasks.length; index++) {
        const task = tasks[index];
        if (task.listUser[0]) {
          for (let j = 0; j < task.listUser.length; j++) {
            const user = task.listUser[j];
            if (user.type === '起案者') {
              check = true;
              break;
            }
          }
        }
      }

      currentStep = currentStep - 1;
      if (step > 1 && step <= 5) {
        setStep(currentStep);

        setFlag(true);
        const newListStep = listStep.map((item) => {
          if (item.id === currentStep) {
            item.active = true;
          } else {
            item.active = false;
          }
          return item;
        });
        setListStep(newListStep, step);
      }
      if (step > 5 && step <= 7) {
        currentStep = check ? currentStep - 1 : currentStep;

        setStep(check ? 5 : step === 7 ? currentStep : 5);
        setFlag(true);
        const newListStep = listStep.map((item) => {
          if (item.id === currentStep - 1) {
            item.active = true;
          } else {
            item.active = false;
          }
          return item;
        });
        setListStep(newListStep, step);
      }
    } catch (error) {}
  };

  const renderStep = (step) => {
    switch (step) {
      case 1:
        return <StepCreateProcess handleListStep={handleListStep} flag={flag} nextStep={handleNextStep} prevStep={handlePrevStep} />;
      case 2:
        return <StepCreateManagerTask nextStep={handleNextStep} prevStep={handlePrevStep} />;
      case 3:
        return <StepCreateTaskContent flag={flag} nextStep={handleNextStep} prevStep={handlePrevStep} />;
      case 4:
        return <StepCreateNotification flag={flag} nextStep={handleNextStep} prevStep={handlePrevStep} />;
      case 5:
        return <StepSaveProcess flag={flag} nextStep={handleNextStep} prevStep={handlePrevStep} />;
      case 6:
        return <StepCreateProcessScheduler nextStep={handleNextStep} prevStep={handlePrevStep} />;
      case 7:
        return <StepCreateTaskDuedate nextStep={handleNextStep} prevStep={handlePrevStep} />;
      default:
        return null;
    }
  };
////////////////phase 2///////////////



  const queryParams = useMemo(() => {
    const params = queryString.parse(location.search);
    if(params.template_id){
      return {
        ...params,
        process_id:params.template_id,
        team_id: 1,
        page: 1,
        limit: 10,
        id: Number.parseInt(params.user) || undefined,
      };
    }else{
       return {
      ...params,
      team_id: teamInfo.listTeam[0]?.team_id,
      page: 1,
      limit: 10,
      id: Number.parseInt(params.user) || undefined,
    };
    }
   
  }, [location.search, teamInfo]);


  useEffect(() => {
    try {
      if (teamInfo.listTeam[0]?.team_id) {
        const searchParam = queryString.parse(location.search);
        if (Object.keys(searchParam).length > 0 && (searchParam.process_id || searchParam.template_id)) {
          (async () => {
            setSpinning(true);
            const result = await processApi.getById(queryParams);
            setSpinning(false);           
            if (result?.data) {
              const processInfo = result.data;
              if(searchParam.template_id){
                const ListTask = processInfo.tasks.map(item=>{
                  const task = {...item}
                  const listUser = task.listUser.filter(item=>item.type)
                  task.listUser = listUser
                  return task
                })
                processInfo.tasks = ListTask

              }
              const execute_day = processInfo.execute_day.filter(item=>{
                return new Date(item.trigger_next_execution_start_time) > new Date()
              }) || []
              let trigger_next_execution_start_time = processInfo.trigger_next_execution_start_time
              if(execute_day.length > 0){ 
                 trigger_next_execution_start_time = execute_day[0].trigger_next_execution_start_time
              }
              // else{
              //   const getMonth = new Date(trigger_next_execution_start_time).getMonth()
              //   for (let index = 1; index < 12; index++) {
              //     const nextMonth = +processInfo.trigger_interval*index + getMonth;
              //     const newDate = new Date(new Date(trigger_next_execution_start_time).setMonth(nextMonth))
              //     if(newDate > new Date){
              //       trigger_next_execution_start_time = newDate
              //       break;
              //     }
              //     if(index === 11){
              //       trigger_next_execution_start_time = new Date()
              //     }
              //   }
              // }
              if(location.pathname.includes('new')){
                const action = processSlice.actions.newProcess({...processInfo,process_id:null,
                  process_name: searchParam.process_id ? `${processInfo.process_name}_コピー` : processInfo.process_name,
                  team_id:teamInfo.listTeam[0]?.team_id,
                  trigger_next_execution_start_time,
                  trigger_start_time:trigger_next_execution_start_time
                });
                dispatch(action);
              }else{
                const action = processSlice.actions.newProcess(processInfo);
                dispatch(action);
              }
              
            }
            if (result?.error && !toast.isActive({ toastId: 'toast_warning' })) {
              toast.warning(result.error, {
                toastId: 'toast_warning',
                position: 'top-right',
                autoClose: 3000,
                closeOnClick: false,
                theme: 'light',
                pauseOnHover: true,
                hideProgressBar: true,
              });
              if(searchParam.process_id){
                navigation({
                  pathname: `/process`,
                });
              }else{
                navigation({
                  pathname: `/template`,
                });
              }
              return;
            }
          })();
        } else {
          const newProcessInfo = { ...processInfo, team_id: teamInfo.listTeam[0]?.team_id, newProcess: true };
          const action = processSlice.actions.newProcess(newProcessInfo);
          dispatch(action);
        }
      }
    } catch (error) {}
  }, [queryParams]);
  return (
    <>
      <Spin spinning={spinning} size='large' className='process_content_spining'>
        <Header>
          <div className='header'>
            <div className='header__content'>
              <h1 className='header__content__title'>
                <FaCog color='#000' /> {processInfo.newProcess ? 'プロセス新規作成' : 'プロセス編集'}
              </h1>
            </div>
          </div>
        </Header>
        <Content>
          <div className='common__flex process__content'>
            <div className='process__content__top'>
              <div className='common__flex process__content__header'>
                <>
                  {processInfo?.process_name ? (
                    <h2 className='common_text-clamp common_text-clamp--1' style={{ textAlign: 'left' }}>
                      プロセス名: {processInfo.process_name}
                    </h2>
                  ) : (
                    <h2 className='common_text-clamp common_text-clamp--1'></h2>
                  )}
                </>
              </div>
              {step !== 5 && !hiddenListStep && <div className={processInfo?.process_name !== '' ? 'process__content__listStep ' : 'process__content__listStep bg__white'}>{processInfo.process_name !== '' && <ListStepComponent processName={processInfo.process_name} listStep={listStep} />}</div>}
              {(step === 5 || hiddenListStep) && <div style={{ background: '#fff',minHeight:0 }} className={'process__content__listStep '}></div>}
            </div>
            <div style={{flex:1,width:'100%'}}>
            <div className='process__content__bottom'>{renderStep(step)}</div>
            </div>
          </div>
        </Content>
      </Spin>
    </>
  );
}

export default ProcessNew;
