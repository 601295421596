import { LoadingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Result, Table, Divider, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaSyncAlt } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { Resizable } from 'react-resizable';
import './css/index.css';

const ResizableTitle = (props) => {
  const { onResize, width,onClick, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
      onMouseDown={e => {
      
      }}
      onResizeStart={e => {
        
      }}
      onClick={e => {
        e.target.className !== 'react-resizable-handle react-resizable-handle-se' &&
        onClick(e);
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

function TableI3links(props) {
  const {
    spinning,
    type = 'checkout',
    position = 'topRight',
    showSizeChanger = true,
    total = 1,
    columns = [],
    dataSource = [],
    handleDoubleClick = () => {},
    handleClick = () => {},
    handlePaginationNexted = () => {},
    checkboxFilterLabel = '遅延あり',
    handleFilterAdmin = null,
    refresh = null,
    titleLocal,
    rowClassName = {},
    className = '',
    defaultPageSize = 10,
    idProcess = null,
    pageSizeOptions = ['10', '25', '50', '100'],
    Pagination = {
      totals: 1,
      limit: 10,
      page: 1,
    },
    scroll = {},
    pendingId = 0,
  } = props;
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectionType, setSelectionType] = useState(type);
  const [columnsTable, setColumnsTable] = useState(columns);
  const location = useLocation();

  const myRowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      const newSelectedRowKeys = [...selectedRowKeys];
      if (handleClick) {
        setSelectedKeys([...newSelectedRowKeys]);
        handleClick([...newSelectedRowKeys]);
      }
    },
  };
  const onchangePage = (values) => {
    if (values) {
      handlePaginationNexted(values);
    }
  };

  const handleResize =
    (index) =>
    (_, { size }) => {
      const newColumns = [...columnsTable];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width,
      };
      setColumnsTable(newColumns);
    };
  const mergeColumns = columnsTable.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
      sortOrder: null,
    }),
  }));

  useEffect(() => {
    if (idProcess === null) {
      setSelectedKeys([]);
    }
  }, [idProcess, columns, location, dataSource]);
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: !refresh && handleFilterAdmin ? 33 : 10, zIndex: 20, justifyContent: 'space-between', width: 250 }}>
        {refresh && (
          <div className=''>
            <Button
              style={{ minWidth: 100, minHeight: 40, lineHeight: '35px', padding: '6px 0px' }}
              className='i3link__btn bg__blue'
              onClick={() => {
                refresh();
              }}
            >
              <FaSyncAlt size={20} cursor={'pointer'} />
            </Button>
          </div>
        )}
        {handleFilterAdmin && (
          <div className='flex__box'>
            <Checkbox
              onChange={(e) => {
                handleFilterAdmin(e.target.checked ? e.target.checked : false);
              }}
            >
              {checkboxFilterLabel}
            </Checkbox>
            {/* <Radio.Group
              onChange={({ target: { value } }) => {
                setSelectionType(value);
                setSelectedKeys([]);
              }}
              value={selectionType}
            >
              <Radio value='checkbox'>Checkbox</Radio>
              <Radio value='radio'>radio</Radio>
            </Radio.Group> */}
          </div>
        )}
      </div>
      <div id='table-resizable-column'>
        <Table
          bordered
          scroll={dataSource.length > 0 ? scroll : false}
          size='small'
          responsive
          className={selectionType === 'radio' ? `table_hidden_radio ${className}` : className}
          columns={mergeColumns}
          dataSource={dataSource}
          rowClassName={rowClassName}
          locale={{
            emptyText: spinning ? <Result icon={<LoadingOutlined />} title='loading...' /> : <Result icon={false} title={!!titleLocal ? titleLocal : '該当データなし'} />,
          }}
          rowSelection={{
            type: selectionType,
            ...myRowSelection,
          }}
          onRow={(record) => {
            return {
              style: {
                background: +record.trn_manage_id === +pendingId ? '#CCC' : '#fff',
              },
              onClick: (event) => {
                if (type === 'radio' && handleClick !== undefined) {
                  setSelectedKeys([record.key]);
                  handleClick(record);
                }
                if (type === 'checkbox' && handleClick !== undefined) {
                  const check = selectedKeys.findIndex((item) => item === record.key);
                  const newSelectedKeys = check === -1 ? [...selectedKeys, record.key] : selectedKeys.filter((item) => item !== record.key);
                  setSelectedKeys([...newSelectedKeys]);
                  handleClick([...newSelectedKeys]);
                }
              },
              onDoubleClick: (event) => {
                if (type === 'radio' && handleClick !== undefined) {
                  setSelectedKeys([record.key]);
                  handleDoubleClick();
                }
                if (type === 'checkbox' && handleClick !== undefined) {
                  setSelectedKeys([...selectedKeys, record.key]);
                }
              },
            };
          }}
          onChange={(e) => {
            console.log();
          }}
          pagination={{
            current: Pagination.page,
            showSizeChanger: showSizeChanger,
            position: [position],
            locale: { items_per_page: '件/ページ' },
            defaultPageSize: Pagination.pageSize || 10,
            pageSizeOptions: pageSizeOptions,
            total: total > 0 ? total : 1,
            onChange: (page, pageSize) => {
              onchangePage({ page, pageSize });
            },
          }}
          components={{
            header: {
              cell: ResizableTitle,
            },
          }}
        />
      </div>
    </>
  );
}

export default TableI3links;
